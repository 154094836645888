import React, { useState, useRef } from "react";

import "./AnimationsLibrary.css";

import { useParams, useLocation } from "react-router";
import useSWR from "swr";
import { SelectFilter } from "../SelectFilter/SelectFilter";
const wait = (amount = 0) =>
  new Promise((resolve) => setTimeout(resolve, amount));

function addCSS(string) {
  document.head.appendChild(document.createElement("style")).innerHTML = string;
}
const fetcher = (url) => fetch(url).then((res) => res.json());

function Builder() {
  const [name, setName] = useState(null);
  const [decorator, setDecorator] = useState(null);

  // const PreviewButton = useRef();

  function addToLibrary() {
    let animationCSS = `.rombo_${name.toLowerCase()} {
      position: relative;
      // animation-play-state: paused;
    }

.rombo_${name.toLowerCase()}:before {
content: '${decorator}';
position: absolute;
top: -25px;
left: 50px;
animation: DecoratorMove 5s linear forwards infinite;
}

@keyframes DecoratorMove {
0%, 100% {
  transform: translateX(0px) translateY(0px);
}
20% {
  transform: translateX(80px) translateY(0px);
}
40% {
  transform: translateX(80px) translateY(60px);
}
60% {
  transform: scale(1.1) translateX(-80px) translateY(60px);
}
70% {
  transform: translateX(-80px) translateY(0px);
}
}
`;

    fetch("https://6tqtmywcq4.execute-api.us-east-1.amazonaws.com/update", {
      method: "post",
      body: JSON.stringify({
        css: animationCSS,
        title: name,
        effect: `rombo_${name.toLowerCase()}`,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //Success code goes here
        alert("form submited");
      })
      .catch(function (err) {
        //Failure
        alert("Error");
      });
  }
  function previewCustomAnimation() {
    // let PreviewButton = document.querySelector("#preview-button");
    // PreviewButton.classList += `.rombo_${name.toLowerCase()}`;

    addCSS(`.rombo_${name.toLowerCase()} {
        position: relative;
        // animation-play-state: paused;
      }

.rombo_${name.toLowerCase()}:before {
  content: "${decorator}";
  position: absolute;
  top: -25px;
  left: 50px;
  animation: DecoratorMove 5s linear forwards infinite;
}

@keyframes DecoratorMove {
  0%, 100% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(80px) translateY(0px);
  }
  40% {
    transform: translateX(80px) translateY(60px);
  }
  60% {
    transform: scale(1.1) translateX(-80px) translateY(60px);
  }
  70% {
    transform: translateX(-80px) translateY(0px);
  }
}
`);
    // PreviewButton.style.animationPlayState = "running";
  }
  return (
    <>
      <h1 class="m-4">Custom Animation Designer</h1>
      <div class="m-4 flex h-40 flex-row bg-slate-200 w-11/12 relative margin-auto">
        <div class="flex flex-col relative items-center justify-center p-2 m-2 rounded-lg bg-white border border-slate-300 flex-1">
          <button
            id="PreviewButton"
            class={`bg-blue-500 text-white px-6
            shadow
            cursor-not-allowed
            custom-animation
            ${name ? `rombo_${name.toLowerCase()}` : ""}
          `}
          >
            Previewer
          </button>
          <button class="flex text-sm rounded-full items-center justify-center text-gray-500 h-5 w-5 absolute bg-gray-200 bottom-0 right-2">
            ▶
          </button>
        </div>
        <div class="flex-1 flex flex-col justify-evenly relative">
          <label class="absolute top-0 right-2 cursor-pointer">Edit css</label>
          <input
            class="bg-slate-100 text-sm px-2 py-1 w-40 rounded-sm shadow-inset"
            placeholder="Animation name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            class="bg-slate-100 text-sm px-2 py-1 w-40 rounded-sm shadow-inset"
            placeholder="Decorator"
            onChange={(e) => setDecorator(e.target.value)}
          />
          <div class="flex">
            <button
              onClick={() => previewCustomAnimation()}
              class="m-1 text-sm w-max px-2 py-1 cursor-pointer bg-slate-500 text-white rounded-sm"
            >
              Preview
            </button>
            <button
              onClick={() => addToLibrary()}
              class="m-1 text-sm w-max px-2 py-1 cursor-pointer bg-slate-900 text-white rounded-sm"
            >
              Add animation to library
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function AnimationsLibrary() {
  const [preview, setPreview] = useState(null);
  const [trigger, setTrigger] = useState(null);
  const [sortedAnimations, setSortedAnimations] = useState([]);
  const { app_id } = useParams();
  //   console.log(Animations);
  const { data: Animations, error } = useSWR(
    "https://rombo-base-animations.s3.eu-west-2.amazonaws.com/animations_v1.json",
    fetcher
  );

  React.useEffect(() => {
    if (Animations) {
      setSortedAnimations(flattenAnimations(Animations));
    }
  }, [Animations]);
  flattenAnimations(Animations);

  React.useEffect(() => {
    if (trigger) {
      // Filter animations only for trigger
      let fileteredAnimations = flattenAnimations(Animations).filter(
        (el) => el.trigger === trigger.value
      );
      setSortedAnimations(fileteredAnimations);
    }
  }, [trigger]);
  async function previewEffect(effect) {
    if (Animations) {
      const EffectCSS = Object.keys(Animations)
        .reduce((a, k) => {
          return [...a, ...Animations[`${k}`]];
        }, [])
        .filter((el) => el.effect === effect)[0].css;
      addCSS(EffectCSS);
      setPreview(effect);
      let timePeriod = EffectCSS.match(/(\d{0,3})(?:s|ms)/, "g");
      let interval =
        timePeriod && timePeriod[1] > 10
          ? timePeriod[1]
          : timePeriod && timePeriod[0]
          ? 1000 * timePeriod[1]
          : timePeriod[1] === 0
          ? 1000
          : 1000;
      await wait(interval === 0 ? interval + 1000 : interval);
      setPreview(null);
    }
  }

  function clearFilters() {
    setTrigger(null);
    setSortedAnimations(flattenAnimations(Animations));
  }
  function flattenAnimations(animations) {
    let result = [];

    for (let key in animations) {
      animations[key].forEach((animation) => {
        result.push({
          trigger: key,
          ...animation,
        });
      });
    }

    return result;
  }

  if (!Animations) return "";

  return (
    <section className="px-16 mt-4 flex flex-col items-start">
      <div className="flex mb-4 align-middle items-center">
        <h1 className="mb-2 mt-2 mr-4">Animation Library</h1>
        <SelectFilter
          field="Trigger"
          onSelected={setTrigger}
          selected={trigger}
          data={Object.keys(Animations).map((k) => {
            return { value: k, title: k };
          })}
        />
        <span
          className="cursor-pointer ml-3 text-sm text-dark-700 hover:text-dark-500"
          onClick={clearFilters}
        >
          Clear filters
        </span>
      </div>
      <div className="animations-library-wrapper">
        {sortedAnimations.map(
          ({ title, effect, direction, character, trigger, amount }) => {
            return (
              <div
                data-character={character ? character : ""}
                className={`animation-preview
                ${preview ? "disallowed" : ""} 
                ${preview === effect ? preview : ""}`}
                onClick={() => {
                  if (preview === null) {
                    previewEffect(effect);
                  } else {
                    console.log("Already previewing...");
                  }
                }}
                style={{
                  animationPlayState: "running",
                  background: `rgb(253 223 66 / ${
                    (trigger.length * 2 - 5) * 10
                  }%)`,
                }}
              >
                <h3 className="animation-preview-title">
                  {title}
                  <sup>
                    {amount}
                    {title.includes("otate") || title.includes("hake")
                      ? "°"
                      : amount === "∞" || amount === "" || !amount
                      ? ""
                      : "%"}
                  </sup>
                </h3>
                <div className="animation-preview-controls">
                  <label className="animation-preview-label">{trigger}</label>
                  <span
                    className={`animation-play-icon ${
                      preview === effect ? "previewing-effect" : ""
                    }`}
                  >
                    {preview === effect ? "○" : "►"}
                  </span>
                </div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
}

export default AnimationsLibrary;

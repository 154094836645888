import { useEffect, useState, useRef } from "react";
import "./Figma.css";
import { UPDATE_USER } from "../../Mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

function Figma({ closeModal, auth, app_id: appId }) {
  const [updateUser] = useMutation(UPDATE_USER);

  async function optInFigma() {
    try {
      await updateUser({
        variables: {
          user_id: auth.username,
          full_name: auth.attributes.name,
          app_id: appId,
          figma_opt: true,
        },
      });
      toast.success("We'll keep you posted");
    } catch (e) {
      console.log("Error notifying Figma setup", e);
    }
  }

  return (
    <div class="flex flex-col relative">
      <aside class="modal-transition relative px-3 flex text-black flex-col justify-between font-semibold text-white py-2 outline outline-gray-200 rounded-sm bg-[#F5F5F5] ">
        <div className="figma-corner p-2 flex justify-between bg-[#EDE1FE] border border-2 border-[#9A4BFE] items-center text-black mt-2 rounded-sm  mb-1">
          <div className="figma-corners-bottom" />
          <div className="flex pl-3">
            <span className="w-6 mr-2 flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 text-gray-800"
                viewBox="0 0 38 57"
                aria-label="Homepage"
              >
                <path
                  fill="#1abcfe"
                  d="M19 28.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0"
                ></path>
                <path
                  fill="#0acf83"
                  d="M0 47.5A9.5 9.5 0 0 1 9.5 38H19v9.5a9.5 9.5 0 1 1-19 0"
                ></path>
                <path
                  fill="#ff7262"
                  d="M19 0v19h9.5a9.5 9.5 0 1 0 0-19z"
                ></path>
                <path
                  fill="#f24e1e"
                  d="M0 9.5A9.5 9.5 0 0 0 9.5 19H19V0H9.5A9.5 9.5 0 0 0 0 9.5"
                ></path>
                <path
                  fill="#a259ff"
                  d="M0 28.5A9.5 9.5 0 0 0 9.5 38H19V19H9.5A9.5 9.5 0 0 0 0 28.5"
                ></path>
              </svg>
            </span>
            <div className="flex ml-1 flex-col">
              Our Figma plugin is coming very soon! <br />
              <span className="text-sm opacity-50">
                Automagically create native Figma prototypes
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="rounded-sm flex items-center bg-white text-black m-0.5 shadow-lg px-2 py-1 text-sm cursor-pointer">
              <svg
                className="w-5 mr-1 text-gray-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 7a2 2 0 1 1 4 0v4a1 1 0 1 0 2 0V7a4 4 0 0 0-8 0v3H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V7Zm-5 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
                  clip-rule="evenodd"
                />
              </svg>
              Get early access
            </span>
            <span
              onClick={() => {
                optInFigma();
              }}
              className="rounded-sm flex items-center bg-black text-white m-0.5 shadow-sm px-2 py-1 text-sm cursor-pointer"
            >
              <svg
                class="w-5 mr-1 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M17.133 12.632v-1.8a5.406 5.406 0 0 0-4.154-5.262.955.955 0 0 0 .021-.106V3.1a1 1 0 0 0-2 0v2.364a.955.955 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C6.867 15.018 5 15.614 5 16.807 5 17.4 5 18 5.538 18h12.924C19 18 19 17.4 19 16.807c0-1.193-1.867-1.789-1.867-4.175ZM8.823 19a3.453 3.453 0 0 0 6.354 0H8.823Z" />
              </svg>
              Notify me
            </span>
          </div>
        </div>
        <div class="flex flex-col opacity-60 px-10 pt-5">
          <div class="flex justify-between w-full pb-2">
            <img
              className="w-1/2 h-fit"
              src="https://assets.rombo.co/FigmaComponent1.png"
            />
            <img
              className="w-1/2 h-fit rounded-lg"
              src="https://assets.rombo.co/FigmaAnimatedComponent.png"
            />
          </div>
          <img src="https://assets.rombo.co/FigmaGif.gif" />
        </div>
      </aside>
    </div>
  );
}

export default Figma;

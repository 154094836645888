import * as Select from "@radix-ui/react-select";
import { forwardRef } from "react";

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import classnames from "classnames";

function SelectFilter({ selected, onSelected, data, field }) {
  return (
    <>
      <Select.Root value={selected} onValueChange={onSelected}>
        <Select.Trigger
          className="capitalize inline-flex rounded-lg text-sm font-bold  text-white bg-dark-800 border border-solid border-dark-900  px-2 py-[8.5px] items-center justify-center rounded  leading-none  bg-white data-[placeholder]:text-white outline-none"
          aria-label={field}
        >
          <Select.Value
            className="text-sm"
            aria-label={selected}
            placeholder={field}
          >
            {selected?.title || field}
          </Select.Value>
          <Select.Icon className="text-white opacity-60 font-semibold">
            <ChevronDownIcon />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content>
            <Select.Viewport className="overflow-hidden px-1 py-1 shadow-lg bg-dark-900 bg-opacity-95 backdrop-blur-lg border border-solid border-dark-900 rounded-md ">
              <Select.Group>
                {data.map((item) => {
                  return <SelectItem value={item}>{item.title}</SelectItem>;
                })}
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </>
  );
}

const SelectItem = forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(
          "flex rounded-sm capitalize relative font-semibold  text-white cursor-pointer items-center m-1 px-2 py-0.5 text-sm bg-dark-800 select-none data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="text-gray-500">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export { SelectFilter, SelectItem };

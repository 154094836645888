import React, { useState, useMemo, useEffect, useRef } from "react";
import useSWR from "swr";
import "./DemoCarousel.css";

const fetcher = (url) => fetch(url).then((res) => res.json());

function addCSS(string) {
  // Double the animation duration
  let doubledDurationString = string.replace(/(\d+ms)/g, function (match, p1) {
    let duration = parseInt(p1);
    return 4 * duration + "ms";
  });

  // Replace "both" or "forwards" with "infinite 500ms"
  let modifiedString = doubledDurationString.replace(
    /\b(both|forwards)\b/g,
    "infinite"
  );

  document.head.appendChild(document.createElement("style")).innerHTML =
    modifiedString;
}

const DemoSlider = () => {
  const { data: Animations, error } = useSWR(
    "https://rombo-base-animations.s3.eu-west-2.amazonaws.com/animations_v1.json",
    fetcher
  );
  if (!Animations) return <div />;

  const renderItems = () => {
    let cumulativeDelay = 0; // Initialize the cumulative delay

    return Object.keys(Animations).map((key) => {
      return Animations[`${key}`].map(
        ({ title, effect, css, direction, character }, i) => {
          addCSS(css);

          // Use regex to extract the animation duration in milliseconds
          const regex = /animation:.*?(\d+)ms/;
          const match = css.match(regex);

          let duration = 0;
          if (match && match[1]) {
            duration = parseInt(match[1], 10);
          }

          // Add the extracted duration to the cumulative delay
          cumulativeDelay += duration;

          if (key === "load" && effect !== "rombo_confetti_load") {
            return (
              <div
                style={{
                  animationPlayState: "running",
                  animationDelay: `${cumulativeDelay}ms`,
                  background: "rgba(255,255,255,0.90)",
                  backdropFilter: "blur(10px)",
                }}
                className={`${effect} mx-2 flex shadow-sm rounded-lg items-center justify-center  w-20 h-20 roijustify-center flex items-center`}
              ></div>
            );
          }
        }
      );
    });
  };

  return (
    <>
      <div
        className="w-full h-96 flex flex-row scroll-left-animation"
        style={{
          width: `calc(6rem * ${Animations["load"].length} * 2)`, // *2 for the duplicated content
          animationDuration: `calc(55s)`,
          marginTop: "40%",
        }}
      >
        {renderItems()}
        {renderItems()} {/* Duplicate the content */}
      </div>
    </>
  );
};

export default DemoSlider;

import React, { useState, useMemo, useEffect, useRef } from "react";
import "./Playground.css";
import { useParams, useLocation } from "react-router";
import { Button } from "../Core/Button";
import { nanoid } from "nanoid";
import toast from "react-hot-toast";

let randomCode = nanoid();

function Playground({ app_url, modalState, element, billing_plan }) {
  const { app_id } = useParams();
  const [event, storeEvent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [url, setUrl] = useState(
    app_url && !app_url.includes("http")
      ? `http://${app_url}?rombo=${randomCode}`
      : `${app_url}?rombo=${randomCode}`
  );
  const styles = {
    expanded: {
      marginTop: "0%",
      width: "100%",
      height: "100%",
      marginLeft: "0%",
    },
    minimized: {
      marginTop: "2.5%",
      width: "95%",
      height: "96%",
      marginLeft: "2.5%",
    },
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const iframeElement = useRef();

  async function eventReceiver(event) {
    if (event.data && app_url) {
      if (event.data.source !== "rombo-animator") return;

      //
      if (event.data.data?.code?.includes(randomCode)) {
        event.source.postMessage("begin", url);
        event.source.postMessage(`${billing_plan}_plan`, url);
        if (element) {
          let stringified = JSON.stringify({ element });
          event.source.postMessage(stringified, url);
        }
        return;
      }
      return storeEvent(event.data);
    }
  }

  useEffect(() => {
    try {
      window.addEventListener("message", eventReceiver, false);
    } catch (e) {
      console.log(e, "Failed adding listener");
    }
  }, [app_url]);

  useEffect(() => {
    if (element !== null) setOpenModal(true);
  }, [element]);

  const openPopup = (url) => {
    // Use destructuring to get screen width and height
    const { width: screenWidth, height: screenHeight } = window.screen;

    // Calculate the desired width and height as 80% of the screen's dimensions
    const width = screenWidth * 0.8;
    const height = screenHeight * 0.8;

    // Calculate the left and top offsets to center the new window
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    // Configure the window properties for "popup mode" using template literals
    const windowFeatures = `
        width=${width},
        height=${height},
        left=${left},
        top=${top},
        directories=no,
        location=no,
        menubar=no,
        resizable=yes,
        scrollbars=yes,
        status=no,
        toolbar=no
    `;

    // Open the new window in popup mode
    window.open(url, "newWindow", windowFeatures.trim());
  };

  function iframeLoaded(e) {
    try {
      let spawned = iframeElement?.current
        ? iframeElement?.current?.contentWindow
        : null;
      if (!spawned) {
        setOpenModal(false);
        return openPopup(url);
      }

      if (element) {
        spawned.postMessage(JSON.stringify({ element }), url);
      }
    } catch (e) {
      setOpenModal(false);
      return openPopup(url);
    }
  }

  if (!app_url) {
    return <></>;
  }
  return (
    <>
      {openModal && (
        <div class="animator-holder delayed-fade-animation">
          <div
            style={expanded ? styles.expanded : styles.minimized}
            class="flex flex-col w-full h-full animator-window"
          >
            <header class="flex h-10 py-4 w-full flex-row items-center justify-between">
              <div class="w-full flex flex-row items-center justify-start">
                <button
                  class="mr-0.5 !bg-red-300 !text-red-900 !border-red-400"
                  onClick={() => setOpenModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
                <button
                  class="mr-0.5 !bg-orange-200 !text-orange-900 !border-orange-400"
                  onClick={toggleExpand}
                >
                  {expanded ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-minimize-2"
                    >
                      <polyline points="4 14 10 14 10 20" />
                      <polyline points="20 10 14 10 14 4" />
                      <line x1="14" y1="10" x2="21" y2="3" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-maximize-2"
                    >
                      <polyline points="15 3 21 3 21 9" />
                      <polyline points="9 21 3 21 3 15" />
                      <line x1="21" y1="3" x2="14" y2="10" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  )}
                </button>
                <button
                  class=" !bg-green-200 !text-green-900 !border-green-400"
                  onClick={() => setUrl(url + "?reset=true")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-refresh-ccw"
                  >
                    <polyline points="1 4 1 10 7 10" />
                    <polyline points="23 20 23 14 17 14" />
                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                  </svg>
                </button>
                <span class="px-2 opacity-50 cursor-not-allowed">
                  {app_url}
                </span>
              </div>
            </header>
            <iframe
              title={url}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              ref={iframeElement}
              src={url}
              onLoad={iframeLoaded}
            ></iframe>
          </div>
        </div>
      )}
      <div className="flex flex-col bg-primary-300 bendy-bg h-full overflow-hidden relative px-3 justify-between font-semibold pt-2 shadow-md outline outline-4 outline-slate-100 shadow-neutral-300 rounded-lg border border-solid border-dark-300">
        <h2 className="font-semibold">
          Try the <span className="play-font">Playground</span>
        </h2>{" "}
        <p className="text-sm font-thin py-1">
          Play around with Rombo's visual animation interface on our example
          site!
        </p>
        <a
          className="font-semibold w-fit underline underline-offset-1 decoration-primary-700 hover:decoration-primary-600 hover:opacity-80 cursor-pointer"
          onClick={() => {
            toast.success("Opened your cloned site");
            if (app_url) setOpenModal(true);
          }}
        >
          Open Playground »
        </a>
        <img
          className="w-2/3 self-center translate-y-[25px] rounded-t-sm border border-solid border-slate-300 shadow-md shadow-neutral-300"
          src={
            "https://s3.eu-west-2.amazonaws.com/rombo.ooo/CleanShot+2024-04-07+at+09.54.16%402x.png"
          }
        />
      </div>
    </>
  );
}

export default Playground;

import React, { useState, useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { UPDATE_APP, CREATE_APP, DOWNLOAD_SITE } from "../Mutations";
import Window from "../Components/Window";
import clsx from "clsx";
import "./Setup.css";
import "../Components/Core/Input.css";
import { useNavigate } from "react-router-dom";
import { GET_APP, CHECK_INSTALLATION } from "../Queries";
const commonProviders = [
  "gmail",
  "hotmail",
  "yahoo",
  "outlook",
  "aol",
  "msn",
  "live",
  "icloud",
  "gmx",
  "yohomail",
  "yandex",
  "tutanota",
  "protonmail",
];

const placeholders = ["example.com", "localhost:3000", "app.myapp.com"];

const OnboardingStep = ({ title, step, activeStep, completedSteps }) => (
  <div
    className={clsx(
      "flex text-lg z-0 m-2 opacity-80 relative px-5 py-3 rounded-lg w-80 text-center",
      activeStep === step
        ? "bg-slate-900 text-slate-50"
        : completedSteps?.includes(step)
        ? "text-slate-200 border border-slate-300"
        : "text-gray-500 border bg-[#f2f2f2] backdrop-blur-xl border-gray-400 cursor-not-allowed"
    )}
  >
    {activeStep === step ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-copy animate-wiggle mx-4"
      >
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-check mx-4"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    )}
    {title}
  </div>
);

const isValidUrl = (url) =>
  /^(https?:\/\/)?(([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*|localhost(:\d+)?)(\/?|\/\w+\.html)?$/i.test(
    url
  );

export const Setup = ({ auth, app_id }) => {
  const [createApp] = useMutation(CREATE_APP);
  const [downloadSite] = useMutation(DOWNLOAD_SITE);
  const [updateApp] = useMutation(UPDATE_APP);

  const { app_name, app_url } = getAppDetails(auth.attributes.email);

  const [extractedUrl, setExtractedUrl] = useState(null);
  const [step, setStep] = useState("create");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [confirmCountdown, setConfirmCountdown] = useState(null);
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [urlError, setUrlError] = useState("");
  const [storedAppId, setStoredAppId] = useState(app_id);

  const [letterIndex, setLetterIndex] = useState(0);

  const timerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const createAppAsync = async () => {
      let result = await createApp({
        variables: {
          app_name,
          app_url,
          user_id: auth.attributes.sub,
          billing_email: auth.attributes.email,
          admin_name: auth.attributes.name || auth.attributes.email,
        },
      });
      setStoredAppId(result.data.createApp.app_id);
      setStep("confirm");
      setCompletedSteps((prevSteps) => [...prevSteps, "create"]);
    };

    if (isValidUrl(app_url)) {
      setExtractedUrl(app_url);
      setUrlError("");
    } else {
      setUrlError("Enter a valid website domain or localhost URL");
    }

    if (!app_id && !auth.attributes["custom:app_id"]) {
      createAppAsync();
    } else {
      setStep("confirm");
      setCompletedSteps((prevSteps) => [...prevSteps, "create"]);
    }
  }, []);

  useEffect(() => {
    if (
      extractedUrl?.length > 0 &&
      confirmCountdown > 0 &&
      !urlError &&
      step === "confirm"
    ) {
      setConfirmCountdown(5);
      const countdownInterval = setInterval(() => {
        setConfirmCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [extractedUrl, confirmCountdown, urlError, step]);

  useEffect(() => {
    if (extractedUrl?.length > 0 && !urlError && step === "confirm") {
      const startCountdown = () => {
        const timerId = setTimeout(() => {
          setCompletedSteps((prevSteps) => [...prevSteps, "confirm"]);
          setStep("clone");
        }, 5000);

        timerRef.current = timerId;
      };

      startCountdown();

      return () => clearTimeout(timerRef.current);
    }
  }, [extractedUrl, completedSteps, setCompletedSteps, setStep, urlError]);

  React.useEffect(() => {
    let interval;
    if (!extractedUrl || extractedUrl?.length === 0) {
      interval = setInterval(
        () => {
          setAnimatedPlaceholder((prevPlaceholder) => {
            const currentPlaceholder = placeholders[placeholderIndex];

            if (isTyping) {
              if (letterIndex < currentPlaceholder.length) {
                setLetterIndex(letterIndex + 1);
                return prevPlaceholder + currentPlaceholder[letterIndex];
              } else {
                setIsTyping(false);
                return currentPlaceholder;
              }
            } else {
              if (prevPlaceholder.length > 0) {
                setLetterIndex(letterIndex - 1);
                return prevPlaceholder.slice(0, -1);
              } else {
                setPlaceholderIndex(
                  (placeholderIndex + 1) % placeholders.length
                );
                setLetterIndex(0);
                setIsTyping(true);
                return "";
              }
            }
          });
        },
        isTyping ? 210 : 70
      ); // Faster backspacing speed
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [extractedUrl, placeholderIndex, letterIndex, isTyping]);

  useEffect(() => {
    let app_id = auth.attributes["custom:app_id"]
      ? auth.attributes["custom:app_id"]
      : storedAppId;

    const updateSiteUrl = async () => {
      await updateApp({
        variables: {
          app_id,
          app_url: extractedUrl,
        },
      });
    };

    const download = async () => {
      try {
        const { data, error } = await downloadSite({
          variables: {
            app_id: app_id,
            app_url: extractedUrl,
          },
        });
        console.log("Downloaded site,", data, error);
        if (data?.downloadSite?.cloned_url !== "InvalidSite") {
          window.location = `
          ${window.location.origin}/animations/${app_id}?preview=true`;
        } else if (data?.downloadSite?.cloned_url === "InvalidSite") {
          setExtractedUrl("");
          setUrlError("We can't find that site. Please try another domain");
          setStep("confirm");
          setCompletedSteps(["create"]);
        } else {
          console.log("Error downloading site");
        }
      } catch (e) {
        setExtractedUrl("");
        setUrlError("We can't find that site. Please try another domain");
        setStep("confirm");
        setCompletedSteps(["create"]);
        console.log("Download error" + e);
      }
    };

    const performActions = async () => {
      if (app_id && extractedUrl) {
        await updateSiteUrl();
        await download();
      }
    };

    if (step === "clone") {
      setTimeout(() => {
        performActions();
      }, 1500);
    }
  }, [step, extractedUrl, auth.attributes]);

  const handleExtractedUrlChange = (e) => {
    clearTimeout(timerRef.current);
    setConfirmCountdown(null);

    const newUrl = e.target.value;
    setExtractedUrl(newUrl);
    setUrlError(
      isValidUrl(newUrl) ? "" : "Enter a valid website domain or localhost URL"
    );
  };

  const handleConfirm = () => {
    if (!urlError) {
      setCompletedSteps((prevSteps) => [...prevSteps, "confirm"]);
      setStep("clone");
    }
  };

  return (
    <Window randomCode={123} element={null}>
      <div className="flex items-center flex-col mt-20">
        <div className="blob" />

        <OnboardingStep
          {...{ step, completedSteps }}
          title={step === "create" ? "Creating app" : "App created"}
          activeStep="create"
        />

        {(step === "confirm" || completedSteps?.includes("confirm")) && (
          <>
            <div
              className={clsx(
                "flex w-80 m-2 p-1 justify-between items-center fade-in border relative rounded-md",
                !completedSteps?.includes("confirm")
                  ? "bg-slate-100 cursor-text border-slate-400"
                  : "bg-[#f2f2f2] border-gray-400 cursor-not-allowed"
              )}
            >
              {completedSteps?.includes("confirm") && (
                <div className="arrow opacity-80 animate-float absolute block z-10 w-max top-2 font-semibold text-xs text-white px-1.5 py-0.5 rounded-md bg-slate-800 left-[98%]">
                  You can change your domain later
                </div>
              )}
              {!extractedUrl && extractedUrl?.length < 1 && (
                <div className="arrow opacity-80 animate-float absolute block z-10 w-max top-2 font-semibold text-xs text-white px-1.5 py-0.5 rounded-md bg-slate-800 left-[98%]">
                  Please enter your website domain
                </div>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-globe pl-2 text-slate-400"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="2" y1="12" x2="22" y2="12" />
                <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
              </svg>
              <input
                value={extractedUrl || ""}
                onChange={handleExtractedUrlChange}
                disabled={completedSteps?.includes("confirm")}
                className="w-48 flex"
                placeholder={animatedPlaceholder}
                onClick={() => clearTimeout(timerRef.current)}
              />

              {!completedSteps?.includes("confirm") && (
                <div
                  className={clsx(
                    "bg-slate-200 rounded-sm font-semibold cursor-pointer px-1.5 py-1 m-1 text-xs",
                    completedSteps?.includes("confirm") &&
                      "opacity-50 cursor-not-allowed"
                  )}
                  onClick={handleConfirm}
                >
                  Confirm{" "}
                  <span className="opacity-50">
                    {confirmCountdown ? confirmCountdown : "»"}
                  </span>
                </div>
              )}
              {urlError && (
                <div className="absolute text-xs bottom-[-20px] left-[-1px] bg-primary-500 border border-primary-900 text-black font-semibold px-2 py-1 rounded">
                  {urlError}
                </div>
              )}
            </div>
          </>
        )}
        {step === "clone" && (
          <OnboardingStep
            {...{ step, completedSteps }}
            title="Creating sandbox"
            activeStep="clone"
          />
        )}
      </div>
    </Window>
  );
};

const getAppDetails = (email) => {
  const [username, domain] = email.split("@");
  const domainName = domain.split(".")[0];

  if (commonProviders.includes(domainName.toLowerCase())) {
    return { app_name: username, app_url: null };
  }

  return { app_name: domainName, app_url: domain };
};

const TagMapping = {
  p: "Paragraph",
  h1: "Heading Text",
  h2: "Heading Text",
  h3: "Heading Text",
  h4: "Heading Text",
  h5: "Heading Text",
  button: "Button",
  a: "Link",
  div: "Container",
  ul: "List",
  span: "Element",
  li: "Link",
  image: "Image",
  img: "Image",
  svg: "Image",
  path: "Image",
};

export default TagMapping;

import React, { Fragment, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Auth from "@aws-amplify/auth";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AnimationsList from "../Components/AnimationsList";
import Receiver from "../Components/Receiver";
import Installer from "../Components/Installer";
import Cloner from "../Components/Cloner";
import Playground from "../Components/Playground";
import "./Animations.css";
import { GET_APP_ANIMATIONS, GET_APP, CHECK_INSTALLATION } from "../Queries";
import {
  ADD_ANIMATION,
  UPDATE_ANIMATION,
  REMOVE_ANIMATION,
  RESET_ANIMATIONS,
  DOWNLOAD_SITE,
} from "../Mutations";
import Figma from "../Components/Figma";

function isLocalhost(url) {
  const pattern = /^(https?:\/\/)?(localhost|127\.0\.0\.1)/;
  return pattern.test(url);
}

function Animations({ auth }) {
  // let navigate = useNavigate();
  const { app_id } = useParams();

  const {
    data: animationsData,
    loading: animationsLoading,
    error: animationsError,
  } = useQuery(GET_APP_ANIMATIONS, {
    variables: { app_id },
  });

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id },
  });

  const {
    data: installData,
    loading: installLoading,
    error: installError,
    refetch: refetchInstallChecker,
  } = useQuery(CHECK_INSTALLATION, {
    variables: { app_id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    // pollInterval: 3000,
  });
  const [removeAnimation, removingAnimation] = useMutation(REMOVE_ANIMATION);
  const [modalState, setModalState] = useState(false);
  const [element, setElement] = useState(null);
  const [downloadSite] = useMutation(DOWNLOAD_SITE);
  let [clonedUrl, setClonedUrl] = useState(appData?.getApp?.cloned_url);
  const [openModal, setOpenModal] = useState(null);

  async function download() {
    const { data } = await downloadSite({
      variables: { app_id, app_url: appData?.getApp?.app_url },
    });
    setClonedUrl(data.downloadSite.cloned_url);
  }

  const location = useLocation();

  useEffect(() => {
    // If params include ?preview=true
    // Open the preview modal

    const searchParams = new URLSearchParams(location.search);
    const previewParam = searchParams.get("preview");

    if (previewParam) {
      setModalState(true);
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (!appData?.getApp?.cloned_url) {
      download();
    }
    // eslint-disable-next-line
  }, [appData && !clonedUrl]);

  if (animationsLoading || installLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <aside></aside>
      </div>
    );
  }
  return (
    <section className="animations-holder">
      {openModal && (
        <div
          className="w-full align-center justify-center bg-white bg-opacity-70 items-center flex fixed backdrop-blur-sm h-full left-0 z-50"
          onClick={() => setOpenModal(false)}
        >
          <div
            className="w-1/2 h-2/3"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={() => setOpenModal(false)}
              className="opacity-50 hover:opacity-100 cursor-pointer text-md p-2 rounded-md"
            >
              Close x
            </div>
            {openModal === "install" ? (
              <Installer
                checking={installLoading}
                platform={installData?.checkInstallation?.platform}
                app_url={appData?.getApp?.app_url}
                app_id={app_id}
                refetch={refetchInstallChecker}
                {...{ auth }}
              />
            ) : openModal === "figma" ? (
              <Figma {...{ auth, app_id }} />
            ) : (
              <div>pricing</div>
            )}
          </div>
        </div>
      )}
      {(installData?.checkInstallation?.is_installed &&
        appData?.getApp?.app_url) ||
      animationsData?.getAppAnimations.length > 0 ? (
        <>
          {" "}
          <AnimationsList
            {...{ removeAnimation, removingAnimation, app_id }}
            getAppAnimations={animationsData?.getAppAnimations}
            {...{ setElement, billing_plan: appData?.getApp?.billing_plan }}
          />
          {installData?.checkInstallation?.is_installed ? (
            <Receiver
              {...{ modalState, element }}
              billing_plan={appData?.getApp?.billing_plan}
              app_url={appData?.getApp?.app_url}
            />
          ) : (
            <>
              <Cloner
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.app_url}
                cloned_url={clonedUrl || appData?.getApp?.cloned_url}
                existingAnimations={animationsData?.getAppAnimations}
                platform={installData?.checkInstallation?.platform}
                setWindowModal={setOpenModal}
              />
            </>
          )}
        </>
      ) : (
        <section className="flex flex-col h-4/5 justify-evenly py-22">
          <div class="flex px-12 ">
            {/* <div className="w-1/3 mx-4 fade-in ">
              <Installer
                checking={installLoading}
                platform={installData?.checkInstallation?.platform}
                app_url={appData?.getApp?.app_url}
                app_id={app_id}
                refetch={refetchInstallChecker}
              />
            </div> */}
            <div className="w-full mx-4 ">
              <Cloner
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.app_url}
                cloned_url={clonedUrl || appData?.getApp?.cloned_url}
                setWindowModal={setOpenModal}
              />
            </div>

            {/* <div className="w-1/3 mx-4 fade-in">
              <Playground
                {...{ modalState, element }}
                billing_plan={appData?.getApp?.billing_plan}
                app_url={appData?.getApp?.cloned_url}
              />
            </div> */}
          </div>
          <h3 className="font-normal text-slate-500 text-center text-sm delayed-fade-animation-2">
            Rombo is not installed at{" "}
            <span className="font-normal underline underline-offset-1 decoration-slate-300">
              {appData?.getApp?.app_url?.replace("https://", "")}
            </span>
          </h3>
        </section>
      )}
    </section>
  );
}

export default Animations;

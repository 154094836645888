import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_APP, CHECK_INSTALLATION } from "../../Queries";
import "./URLChanger.css";
import { Button } from "../Core/Button";
import { UPDATE_APP } from "../../Mutations";

export const UrlChanger = ({ initialUrl, app_id, onUrlChange }) => {
  const [url, setUrl] = useState(initialUrl);
  const [editingUrl, setEditingUrl] = useState(false);
  const [width, setWidth] = useState(
    initialUrl ? initialUrl.length * 7.25 : 100
  );
  const [changeAppUrl, changingAppUrl] = useMutation(UPDATE_APP);
  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id },
  });

  useEffect(() => {
    console.log("");
    setUrl(initialUrl);
  }, [initialUrl]);

  function isValidUrl(url) {
    const regex =
      /^(https?:\/\/)(localhost|[\w-]+(\.[\w-]+)+)(:[\d]+)?(\/\S*)?$/;
    return regex.test(url);
  }

  const handleSave = async (newUrl) => {
    // check url is valid, https or http, can be localhost
    if (!isValidUrl(newUrl)) {
      alert("Invalid URL, must start with https or http");
      return;
    }
    try {
      let mutation = await changeAppUrl({
        variables: { app_id, app_url: newUrl },
        optimisticResponse: {
          updateApp: {
            __typename: "App",
            ...appData.getApp,
            app_id: app_id,
            app_url: newUrl,
          },
        },
        update: (proxy, { data: { changeAppUrl } }) => {
          const cachedQuery = proxy.readQuery({
            query: GET_APP,
            variables: {
              app_id: app_id,
            },
          });
          let foundData = cachedQuery.getApp;
          // // Find the object in cachedQuery which has the id, replace is_live with the new toggleAnimation islive
          const replacedQuery = {
            ...foundData,
            app_url: newUrl,
          };

          proxy.writeQuery({
            query: GET_APP,
            variables: {
              app_id: app_id,
            },
            data: { getApp: replacedQuery },
          });
        },
        refetchQueries: [
          {
            query: GET_APP,
            variables: { app_id: app_id },
          },
          {
            query: CHECK_INSTALLATION,
            variables: { app_id: app_id },
          },
        ],
      });
      if (mutation && mutation.data.updateApp.app_url === newUrl) {
        setEditingUrl(false);
        if (onUrlChange) {
          onUrlChange(url);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // If editing url, and enter key is hit, handle save
  useEffect(() => {
    if (editingUrl) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          let newUrl = e.target.value;
          handleSave(newUrl);
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [editingUrl]);

  if (appLoading) {
    return <></>;
  }
  return (
    <div className="bg-slate-50 text-dark flex items-center rounded-sm px-1 py-0.5 cursor-text text-sm">
      {editingUrl ? (
        <input
          autoFocus
          value={url}
          type="url"
          onChange={(e) => {
            setUrl(e.target.value);
          }}
          className="ml-0 pr-0 text-sm text-dark-900 bg-transparent outline-none border-none"
        />
      ) : (
        <span className="pr-1 text-dark-800">{url}</span>
      )}
      <span
        className="text-xs font-bold px-1 rounded-sm cursor-pointer"
        onClick={editingUrl ? () => handleSave(url) : () => setEditingUrl(true)}
      >
        {!editingUrl
          ? "Edit"
          : editingUrl && changingAppUrl.data
          ? "Confirm"
          : editingUrl && changingAppUrl.loading
          ? "…"
          : "Confirm"}
      </span>
    </div>
  );
};

import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Auth from "@aws-amplify/auth";
import { useParams, useLocation } from "react-router";
import { Button } from "../Components/Core/Button";
import { UrlChanger } from "../Components/URLChanger/URLChanger";
import { GET_APP } from "../Queries";
import UsageTracker from "../Components/UsageTracker/UsageTracker";
import { RESET_ANIMATIONS, UPDATE_APP, DELETE_APP } from "../Mutations";
import { TooltipWrapper } from "../Helpers/TooltipWrapper";
import toast from "react-hot-toast";
import triggerStripeBillingPortal from "../Helpers/stripeBillingPortal";

function SettingHolder({ title, children, danger }) {
  return (
    <li
      className={`flex w-full rounded-md m-2 p-2 flex-col border bg-slate-100 border-slate-200 shadow-sm ${
        danger ? "bg-red-200 border-red-500" : ""
      }`}
    >
      <h2 class="font-bold rounded-sm p-2 ">{title}</h2>
      <div class="pl-2">{children}</div>
    </li>
  );
}
function Settings({ app_url, user_id }) {
  const { app_id } = useParams();
  const [resetAnimations, resettingAnimations] = useMutation(RESET_ANIMATIONS);
  const [updateBillingNotification, updatingBillingNotification] =
    useMutation(UPDATE_APP);
  const [deleteApp, deletingApp] = useMutation(DELETE_APP);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const typeform = useRef();

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id },
  });

  if (appLoading) return <></>;

  if (appData !== null) {
    const { billing_notifications } = appData?.getApp;
    return (
      <>
        <dialog
          open={confirmDeletion}
          className="absolute z-50 t-0 l-0 w-full h-full bg-slate-100 bg-opacity-80 backdrop-blur-sm"
        >
          <div className="flex flex-col items-center  justify-center w-full h-full">
            <div className="flex flex-col border border-solid border-slate-200 items-left justify-center bg-slate-50 rounded-md shadow-md p-8 shadow-md">
              <h1 className="text-md text-slate-500">
                {confirmDeletion === "reset"
                  ? "Reset Animations"
                  : "Delete App"}
              </h1>
              <p className="text-md text-slate-500 mb-2">
                Are you sure you want to
                {confirmDeletion === "reset" ? " reset all animations?" : ""}
                {confirmDeletion === "delete"
                  ? " delete your entire workspace? "
                  : ""}
                <br />
                <b className="underline underline-offset-2 decoration-slate-300">
                  This action cannot be undone
                </b>
              </p>
              <div className="flex flex-row items-center justify-center w-full">
                <div class="m-2">
                  <Button
                    onClick={() => {
                      if (confirmDeletion === "reset") {
                        resetAnimations({ variables: { app_id } });
                      }
                      if (confirmDeletion === "delete") {
                        deleteApp({ variables: { app_id, user_id } });
                      }
                    }}
                    shadow
                    danger
                  >
                    Confirm {confirmDeletion === "reset" ? "Reset" : "Delete"}
                  </Button>
                </div>
                <div class="m-2">
                  <Button
                    normal
                    dark
                    showBorder
                    onClick={() => {
                      setConfirmDeletion(false);
                    }}
                    shadow
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </dialog>
        <main>
          <section className="animations">
            <ul class="flex flex-row items-top w-full pt-10 px-10">
              <SettingHolder title="App Configuration">
                <label class="flex items-center rounded-sm">
                  <span class="w-full opacity-50">App URL</span>
                  <UrlChanger
                    initialUrl={appData?.getApp.app_url}
                    app_id={app_id}
                    onUrlChange={(url) => {
                      toast.success("Updated Workspace URL");
                    }}
                  />
                </label>
                <label class="flex items-center justify-between rounded-sm my-2">
                  <span class="opacity-50">App ID</span>
                  <div class="flex items-center align-center p-0 m-0">
                    <div class="text-sm opacity-50">{app_id}</div>
                  </div>
                </label>
                <label class="flex items-center justify-between rounded-sm my-2">
                  <span class="opacity-50">Rombo Animator URL</span>
                  <TooltipWrapper content="Click to copy">
                    <div
                      class="cursor-pointer text-sm opacity-50 hover:bg-slate-300 bg-slate-200 px-1 rounded-sm py-0.5 flex items-center align-center p-0 m-0"
                      onClick={() => {
                        window.navigator.clipboard.writeText(
                          `https://rombo.live/${app_id}.js`
                        );
                        toast.success("Copied URL to clipboard");
                      }}
                    >
                      https://rombo.live/{app_id}.js
                    </div>
                  </TooltipWrapper>
                </label>
                <label class="flex items-center justify-between rounded-sm my-2">
                  <span class="opacity-50">Billing notifications</span>
                  <label class="flex-none leading-normal h-full animation-switchbox !mr-2">
                    <input
                      checked={billing_notifications}
                      onChange={() => {
                        updateBillingNotification({
                          variables: {
                            app_id,
                            billing_notifications: !billing_notifications,
                          },
                          optimisticResponse: {
                            updateApp: {
                              __typename: "App",
                              app_id: app_id,
                              billing_notifications: !billing_notifications,
                            },
                          },
                          update: (proxy, { data: { toggleAnimation } }) => {
                            const cachedQuery = proxy.readQuery({
                              query: GET_APP,
                              variables: {
                                app_id: app_id,
                              },
                            });
                            console.log(cachedQuery, "cachedQuery");
                            let foundData = cachedQuery.getApp;

                            // // Find the object in cachedQuery which has the id, replace is_live with the new toggleAnimation islive
                            const replacedQuery = {
                              ...foundData,
                              billing_notifications: !billing_notifications,
                            };

                            proxy.writeQuery({
                              query: GET_APP,
                              variables: {
                                app_id: app_id,
                              },
                              data: { getApp: replacedQuery },
                            });
                          },
                          refetchQueries: [
                            {
                              query: GET_APP,
                              variables: { app_id: app_id },
                            },
                          ],
                        });
                        if (!billing_notifications) {
                          toast.success("Billing notifications enabled");
                        } else {
                          toast.success("Billing notifications disabled");
                        }
                      }}
                      type="checkbox"
                    />
                    <span class="animation-switchbox-slider"></span>
                  </label>
                </label>
              </SettingHolder>
              <SettingHolder title="App Billing">
                <UsageTracker appId={app_id} />
                {appData?.getApp?.billing_plan && (
                  <a
                    onClick={async () =>
                      await triggerStripeBillingPortal(
                        app_id,
                        appData?.getApp?.stripe_customer_id,
                        appData?.getApp?.stripe_subscription_id,
                        appData?.getApp?.customer_email
                      )
                    }
                  >
                    Manage billing plan on Stripe
                  </a>
                )}
              </SettingHolder>
            </ul>
            <ul class="flex flex-row items-top w-full px-10">
              <SettingHolder danger="true" title="Danger Zone">
                <div class="flex items-center justify-between rounded-sm my-2">
                  <span class="opacity-50">Reset all Animations</span>
                  <div class="flex items-center align-center p-0 m-0">
                    <Button
                      onClick={() => {
                        setConfirmDeletion("reset");
                      }}
                      shadow
                      danger
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                {/* Delete app */}
                <div class="flex items-center justify-between rounded-sm my-2">
                  <span class="opacity-50">Delete</span>
                  <div class="flex items-center align-center p-0 m-0">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmDeletion("delete");
                      }}
                      shadow
                      danger
                    >
                      Delete App
                    </Button>
                  </div>
                </div>
              </SettingHolder>
            </ul>
          </section>
        </main>
      </>
    );
  }
}

export default Settings;

import React from "react";
import "./Window.css";

export default function Window({ app_url, randomCode, element, children }) {
  const [openModal, setOpenModal] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [url, setUrl] = React.useState(null);

  //
  // app_url && !app_url.includes("http")
  // ? `http://${app_url}?rombo=${randomCode}`
  // : `${app_url}?rombo=${randomCode}`

  const styles = {
    expanded: {
      marginTop: "0%",
      width: "100%",
      height: "100%",
      marginLeft: "0%",
    },
    minimized: {
      marginTop: "2.5%",
      width: "95%",
      height: "97.5%",
      marginLeft: "2.5%",
    },
  };
  const iframeElement = React.useRef();

  const openPopup = (url) => {
    // Use destructuring to get screen width and height
    const { width: screenWidth, height: screenHeight } = window.screen;

    // Calculate the desired width and height as 80% of the screen's dimensions
    const width = screenWidth * 0.8;
    const height = screenHeight * 0.8;

    // Calculate the left and top offsets to center the new window
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    // Configure the window properties for "popup mode" using template literals
    const windowFeatures = `
        width=${width},
        height=${height},
        left=${left},
        top=${top},
        directories=no,
        location=no,
        menubar=no,
        resizable=yes,
        scrollbars=yes,
        status=no,
        toolbar=no
    `;

    // Open the new window in popup mode
    window.open(url, "newWindow", windowFeatures.trim());
  };

  function iframeLoaded(e) {
    try {
      let spawned = iframeElement?.current
        ? iframeElement?.current?.contentWindow
        : null;
      if (!spawned) {
        console.log("Failed to load iframe?");
        setOpenModal(false);
        return openPopup(url);
      }

      if (element) {
        spawned.postMessage(JSON.stringify({ element }), url);
      }
    } catch (e) {
      console.log("Failed to load iframe", e);
      setOpenModal(false);
      return openPopup(url);
    }
  }

  return (
    <>
      {openModal && (
        <div class="animator-holder delayed-fade-animation">
          <div
            style={expanded ? styles.expanded : styles.minimized}
            class="flex flex-col w-full h-full animator-window"
          >
            <header class="flex pt-1.5 w-full flex-row items-center justify-between">
              <div class="w-full flex flex-row items-center justify-start">
                <button
                  class="aw-btn mr-0.5 !bg-red-300 !text-red-900 !border-red-400"
                  onClick={() => setOpenModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
                <button
                  class="aw-btn mr-0.5 !bg-orange-200 !text-orange-900 !border-orange-400"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-minimize-2"
                    >
                      <polyline points="4 14 10 14 10 20" />
                      <polyline points="20 10 14 10 14 4" />
                      <line x1="14" y1="10" x2="21" y2="3" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-maximize-2"
                    >
                      <polyline points="15 3 21 3 21 9" />
                      <polyline points="9 21 3 21 3 15" />
                      <line x1="21" y1="3" x2="14" y2="10" />
                      <line x1="3" y1="21" x2="10" y2="14" />
                    </svg>
                  )}
                </button>
                <button
                  class="aw-btn  !bg-green-200 !text-green-900 !border-green-400"
                  onClick={() => setUrl(url + "?reset=true")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-refresh-ccw"
                  >
                    <polyline points="1 4 1 10 7 10" />
                    <polyline points="23 20 23 14 17 14" />
                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                  </svg>
                </button>
                <span class="px-2 opacity-50 cursor-not-allowed">
                  {app_url}
                </span>
              </div>
            </header>
            {app_url ? (
              <iframe
                title={url}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                ref={iframeElement}
                src={url}
                onLoad={iframeLoaded}
              ></iframe>
            ) : (
              <div className="">{children}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

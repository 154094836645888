import { gql } from "@apollo/client";

const GET_APP_ANIMATIONS = gql`
  query getAppAnimations($app_id: ID!) {
    getAppAnimations(app_id: $app_id) {
      id
      animation
      element_path
      element_text
      animation_trigger
      date_updated
      is_live
      animation_speed
      animation_delay
      animation_amount
    }
  }
`;

const CHECK_INSTALLATION = gql`
  query checkInstallation($app_id: ID!) {
    checkInstallation(app_id: $app_id) {
      app_id
      app_url
      is_installed
      install_detail
      platform
      content_security
    }
  }
`;

const GET_APP = gql`
  query getApp($app_id: ID!) {
    getApp(app_id: $app_id) {
      app_id
      app_url
      app_name
      page_views
      billing_plan
      billing_notifications
      cloned_url
      screenshot_url
      admin_name
      billing_email
      stripe_customer_id
      stripe_subscription_id
    }
  }
`;

const GET_USER = gql`
  query getUser($user_id: ID!) {
    getUser(user_id: $user_id) {
      user_id
      full_name
      app_id
      app_name
      email
    }
  }
`;

export { GET_APP_ANIMATIONS, GET_APP, CHECK_INSTALLATION, GET_USER };

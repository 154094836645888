import { useEffect, useState } from "react";
// import AnimationObj from "./AnimationObj";
import { useMutation } from "@apollo/client";
import { GET_APP_ANIMATIONS } from "../../Queries";
import { TOGGLE_ANIMATION } from "../../Mutations";
import "./AnimationsList.css";
import useSWR from "swr";
import {
  ClockIcon,
  ForwardIcon,
  ArrowTopRightOnSquareIcon,
  PlayIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { SelectFilter, SelectItem } from "../SelectFilter/SelectFilter";
import TagMapping from "../../Helpers/TagMapping";
import InstallGraphic from "../Installer/InstallGraphic";
import toast from "react-hot-toast";
import { TooltipWrapper } from "../../Helpers/TooltipWrapper";

function truncate(input, length, delim) {
  return input && input.split(delim).length > length
    ? `${input.split(delim).slice(0, length).join(delim)}...`
    : input;
}

function Sorter({ sortBy, field, sortDirection, sortedField }) {
  return (
    <span class="sort-icon" onClick={() => sortBy(field)}>
      <svg
        class="pl-1"
        height="12"
        viewBox="0 0 32 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.567 0.75C15.7594 0.416667 16.2406 0.416667 16.433 0.75L31.1554 26.25C31.3479 26.5833 31.1073 27 30.7224 27H1.27757C0.892668 27 0.652106 26.5833 0.844556 26.25L15.567 0.75Z"
          fill={
            sortDirection === "ascending" && sortedField === field
              ? "#f5f5f5"
              : "#050505"
          }
        />
        <path
          d="M16.433 61.25C16.2406 61.5833 15.7594 61.5833 15.567 61.25L0.84456 35.75C0.65211 35.4167 0.892672 35 1.27757 35L30.7224 35C31.1073 35 31.3479 35.4167 31.1554 35.75L16.433 61.25Z"
          fill={
            sortDirection === "descending" && sortedField === field
              ? "#f5f5f5"
              : "#050505"
          }
        />
      </svg>
    </span>
  );
}

function AnimationsList({
  app_id,
  getAppAnimations,
  removeAnimation,
  removingAnimation,
  setElement,
  billing_plan,
}) {
  const [toggleAnimation, togglingAnimation] = useMutation(TOGGLE_ANIMATION);
  const [toggle, setToggle] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [sortDirection, setSortDirection] = useState("ascending");
  const [sortedAnimations, setSortedAnimations] = useState(null);
  const [sortedField, setSortedField] = useState(null);
  const [animationFilterSelected, setAnimationFilter] = useState(null);
  const [elementFilterSelected, setElementFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  let animations = sortedAnimations ? sortedAnimations : getAppAnimations;

  function searchForAnimation(searchTerm) {
    if (searchTerm.length === 0) {
      return setSortedAnimations(getAppAnimations);
    }
    let filteredAnimations = animations.filter((item) => {
      return item.element_text.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setSortedAnimations(filteredAnimations);
  }

  function sortBy(key) {
    setSortedField(key);
    let copy = [...animations];
    let sorted = copy.sort((a, b) =>
      sortDirection === "ascending"
        ? a[`${key}`].localeCompare(b[`${key}`])
        : b[`${key}`].localeCompare(a[`${key}`])
    );
    if (sortDirection === "descending") {
      setSortDirection("ascending");
    } else {
      setSortDirection("descending");
    }
    setSortedAnimations(sorted);
  }

  const fetcher = (u) => fetch(u).then((res) => res.json());
  const {
    data: romboAnimations,
    loading: romboAnimationsLoading,
    error: romboAnimationsError,
  } = useSWR(
    "https://rombo-base-animations.s3.eu-west-2.amazonaws.com/animations_v1.json",
    fetcher
  );

  // function sendToWindow(element) {
  //   setModalState(element);
  // }

  async function deleteAnimation({
    id,
    app_id,
    animation,
    element_path,
    element_text,
  }) {
    await removeAnimation({
      variables: {
        id,
        app_id,
        animation,
        element: element_path,
      },
      optimisticResponse: {
        removeAnimation: {
          __typename: "Animation",
          id,
          app_id,
          animation,
          element_path,
          element_text,
        },
      },
      update: (proxy, { data: { removeAnimation } }) => {
        // Read the data from our cache for this query.
        const cachedQuery = proxy.readQuery({
          query: GET_APP_ANIMATIONS,
          variables: {
            app_id: app_id,
          },
        });
        let foundData = [...cachedQuery.getAppAnimations];
        // // Find the object in cachedQuery which has the id, replace is_live with the new toggleAnimation islive
        const replacedQuery = foundData.filter((animation) => {
          if (animation.id !== id) {
            return animation;
          }
        });
        proxy.writeQuery({
          query: GET_APP_ANIMATIONS,
          variables: {
            app_id: app_id,
          },
          data: { getAppAnimations: replacedQuery },
        });
      },
      refetchQueries: [
        {
          query: GET_APP_ANIMATIONS,
          variables: { app_id: app_id },
        },
      ],
    });
  }

  function findAnimation(animation) {
    if (romboAnimations)
      return Object.keys(romboAnimations)
        .reduce((a, k) => {
          return [...a, ...romboAnimations[`${k}`]];
        }, [])
        .filter((el) => el.effect === animation)[0];
  }

  useEffect(() => {
    let filteredAnimations;

    if (elementFilterSelected) {
      filteredAnimations = getAppAnimations.filter((item) => {
        let elementPath =
          item.element_path.match(/(.*)\>(.*)/) &&
          TagMapping[`${item.element_path.match(/(.*)\>(.*)/)[2].trim()}`]
            ? item.element_path.match(/(.*)\>(.*)/)[2].trim()
            : "element";
        return elementPath === elementFilterSelected.value;
      });
    }
    if (animationFilterSelected) {
      filteredAnimations = getAppAnimations.filter((item) => {
        return item.animation === animationFilterSelected.value;
      });
    }

    if (elementFilterSelected && animationFilterSelected) {
      filteredAnimations = getAppAnimations.filter((item) => {
        let elementPath =
          item.element_path.match(/(.*)\>(.*)/) &&
          TagMapping[`${item.element_path.match(/(.*)\>(.*)/)[2].trim()}`]
            ? item.element_path.match(/(.*)\>(.*)/)[2].trim()
            : "element";
        return (
          elementPath === elementFilterSelected.value &&
          item.animation === animationFilterSelected.value
        );
      });
    }

    setSortedAnimations(filteredAnimations);
  }, [elementFilterSelected, animationFilterSelected]);

  function animationFilter() {
    let UsedAnimations = getAppAnimations.reduce((res, { animation }) => {
      let formattedAnimation = findAnimation(animation)
        ? `${findAnimation(animation).title} ${
            findAnimation(animation).direction
          }`
        : animation;
      if (!res.some(({ value }) => value === animation)) {
        res.push({ title: formattedAnimation, value: animation });
      }

      return res;
    }, []);

    return (
      <SelectFilter
        onSelected={setAnimationFilter}
        selected={animationFilterSelected}
        data={UsedAnimations}
        field="animation"
      />
    );
  }
  function clearFilters() {
    setAnimationFilter(null);
    setElementFilter(null);
    setSortedAnimations(getAppAnimations);
  }

  function elementFilter() {
    let UsedElements = getAppAnimations.reduce((res, { element_path }) => {
      let elementTitle =
        element_path.match(/(.*)\>(.*)/) &&
        TagMapping[`${element_path.match(/(.*)\>(.*)/)[2].trim()}`]
          ? TagMapping[`${element_path.match(/(.*)\>(.*)/)[2].trim()}`]
          : "Element";
      let elementPath =
        element_path.match(/(.*)\>(.*)/) &&
        TagMapping[`${element_path.match(/(.*)\>(.*)/)[2].trim()}`]
          ? element_path.match(/(.*)\>(.*)/)[2].trim()
          : "element";
      if (!res.some(({ value }) => value === elementPath)) {
        res.push({ title: elementTitle, value: elementPath });
      }

      return res;
    }, []);

    return (
      <SelectFilter
        data={UsedElements}
        onSelected={setElementFilter}
        selected={elementFilterSelected}
        field="element"
      />
    );
  }

  if (romboAnimationsError) return <div>Failed to load</div>;
  if (!romboAnimations) return <></>;

  if (romboAnimations && getAppAnimations && getAppAnimations.length > 0) {
    return (
      <main class="fade-in">
        <section class="px-16 mt-2 flex justify-between items-center">
          <span class="bg-slate-50 rounded-md text-slate-500 border border-slate-300 border-solid px-2 py-1">
            ⌕
            <input
              placeholder="Search element text..."
              class=""
              onChange={(e) => {
                searchForAnimation(e.target.value);
              }}
            />
          </span>
          <div>
            <span className="mx-1">{animationFilter()}</span>
            <span className="mx-1">{elementFilter()}</span>
            <span
              className="cursor-pointer text-sm text-dark-700 pl-1 hover:text-dark-500"
              onClick={clearFilters}
            >
              Clear filters
            </span>
          </div>
        </section>
        <ul class="flex flex-col items-center px-16 py-4">
          {!billing_plan && getAppAnimations.length > 2 && (
            <div className="bg-primary-300 text-sm font-semibold p-2 outline outline-primary-900 mb-2 text-dark-900 w-full rounded-sm">
              Free plan is limited to 3 animations. Upgrade to add more!
            </div>
          )}
          {animations.map(
            (
              {
                id,
                element_text,
                element_path,
                animation,
                is_live,
                animation_trigger,
                splitter,
                clip,
                date_updated,
                animation_speed,
                animation_delay,
              },
              index
            ) => {
              const DaysAgoAdded = Math.round(
                Math.abs(
                  (new Date().getTime() - new Date(date_updated).getTime()) /
                    8.64e7
                )
              );
              if (element_text !== null)
                return (
                  <li
                    class={`flex flex-row border-b border-r border-l ${
                      index === 0 ? "border-t " : "border-b"
                    } 
                  ${index === animations.length - 1 ? "" : ""}
                  w-full	border-slate-200
                  px-2                  
                  py-1 items-center justify-between`}
                  >
                    {/* <label class="flex-none leading-normal h-full animation-switchbox !mr-2">
                      <input
                        checked={is_live}
                        onChange={() =>
                          toggleAnimation({
                            variables: {
                              id,
                              app_id,
                              animation,
                              text: element_text,
                              trigger: animation_trigger,
                              element: element_path,
                            },
                            optimisticResponse: {
                              toggleAnimation: {
                                __typename: "Animation",
                                id,
                                animation,
                                element_path,
                                element_text,
                                is_live: !is_live,
                              },
                            },
                            update: (proxy, { data: { toggleAnimation } }) => {
                              // Read the data from our cache for this query.
                              const cachedQuery = proxy.readQuery({
                                query: GET_APP_ANIMATIONS,
                                variables: {
                                  app_id: app_id,
                                },
                              });
                              let foundData = [...cachedQuery.getAppAnimations];
                              // // Find the object in cachedQuery which has the id, replace is_live with the new toggleAnimation islive
                              const replacedQuery = foundData.map(
                                (animation) => {
                                  if (animation.id === toggleAnimation.id) {
                                    return { ...animation, is_live: !is_live };
                                  } else {
                                    return animation;
                                  }
                                }
                              );
                              proxy.writeQuery({
                                query: GET_APP_ANIMATIONS,
                                variables: {
                                  app_id: app_id,
                                },
                                data: { getAppAnimations: replacedQuery },
                              });
                            },
                            refetchQueries: [
                              {
                                query: GET_APP_ANIMATIONS,
                                variables: { app_id: app_id },
                              },
                            ],
                          })
                        }
                        type="checkbox"
                      />
                      <span class="animation-switchbox-slider"></span>
                    </label> */}
                    <div class="flex flex-1 flex-col">
                      <h2 class="leading-normal text-slate-800 flex-1 px-2 py-1 border-slate-300">
                        {" "}
                        {truncate(element_text, 4, " ").length > 0
                          ? truncate(element_text, 4, " ")
                          : "No element text"}
                      </h2>
                      <div class="flex flex-1 flex-row pb-2 items-center">
                        <label class="mx-1 leading-normal rounded-full bg-slate-300/25 px-2 text-xs font-semibold text-slate-500">
                          {element_path.match(/(.*)\>(.*)/) &&
                          TagMapping[
                            `${element_path.match(/(.*)\>(.*)/)[2].trim()}`
                          ]
                            ? TagMapping[
                                `${element_path.match(/(.*)\>(.*)/)[2].trim()}`
                              ]
                            : "Element"}
                        </label>
                        {findAnimation(animation) &&
                          findAnimation(animation).title && (
                            <label class="mx-1 leading-normal rounded-full bg-primary-500 px-2 text-xs font-semibold text-slate-900">
                              {findAnimation(animation) &&
                                findAnimation(animation).title}{" "}
                            </label>
                          )}
                        <label class="mx-1 leading-normal rounded-full flex items-center bg-slate-900 pl-2 pr-2 text-xs font-semibold text-white">
                          {animation_speed}
                          <span class="opacity-50">ms</span>
                          {!isNaN(animation_delay) ? (
                            ``
                          ) : (
                            <>
                              `• ${animation_delay}ms`
                              <ClockIcon className="pl-px w-3 h-3 opacity-100" />
                            </>
                          )}
                        </label>
                        <label class="flex items-center mx-1 leading-normal rounded-full bg-slate-100 px-2 text-xs font-semibold text-slate-900">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="w-4 pr-1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 17C16.1046 17 17 16.1046 17 15C17 13.8954 16.1046 13 15 13C13.8954 13 13 13.8954 13 15C13 16.1046 13.8954 17 15 17Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM5 18V7H19V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z"
                              fill="currentColor"
                            />
                          </svg>
                          {DaysAgoAdded !== 0
                            ? `Added ${DaysAgoAdded} day${
                                DaysAgoAdded > 1 ? "s" : ""
                              } ago`
                            : "Added today"}
                        </label>
                      </div>
                    </div>
                    <div className="flex">
                      <TooltipWrapper content="Play animation on site">
                        <a
                          onClick={() => setElement(element_path)}
                          className="mx-1 cursor-pointer items-center text-sm font-medium relative rounded-full transition-colors flex justify-center text-emphasis h-9 px-4 py-2.5 bg-slate-50 bg-default hover:bg-slate-100 min-h-[36px] min-w-[36px] !p-2"
                        >
                          <PlayIcon className="w-3 h-3" />
                        </a>
                      </TooltipWrapper>

                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                          <button className="mx-1 cursor-pointer items-center text-sm font-medium relative rounded-full transition-colors flex justify-center text-emphasis h-9 px-4 py-2.5 bg-slate-50 bg-default hover:bg-slate-100 min-h-[36px] min-w-[36px] !p-2">
                            <EllipsisHorizontalIcon className="w-4 h-4" />
                          </button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                          <DropdownMenu.Content
                            className="DropdownMenuContent"
                            sideOffset={5}
                            class="shadow-dropdown w-50 bg-slate-900 text-white border relative z-10 ml-1.5 origin-top-right rounded-md text-sm [&>*:first-child]:mt-1 [&>*:last-child]:mb-1"
                          >
                            <DropdownMenu.Item
                              onClick={async () =>
                                await deleteAnimation({
                                  id,
                                  animation,
                                  element_path,
                                  element_text,
                                  app_id,
                                })
                              }
                              className="m-1 rounded-sm flex-row items-center cursor-pointer px-1.5 py-1 flex focus:ring-brand-800 hover:bg-slate-50 hover:outline-none hover:text-dark-900 text-default text-sm ring-inset focus:outline-nones font-semibold"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                className="w-5 pl-1 pr-1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Delete
                            </DropdownMenu.Item>
                            <DropdownMenu.Item
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${findAnimation(animation).css.replace(
                                    "animation-play-state: paused",
                                    "animation-play-state: running"
                                  )}`
                                );
                                toast.success("Copied CSS to clipboard");
                              }}
                              className="m-1 rounded-sm flex-row items-center cursor-pointer px-1.5 py-1 flex focus:ring-brand-800 hover:bg-slate-50 hover:outline-none hover:text-dark-900 text-default text-sm ring-inset focus:outline-nones "
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                className="w-5 pl-1 pr-1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M13 7H7V5H13V7Z" fill="currentColor" />
                                <path
                                  d="M13 11H7V9H13V11Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M7 15H13V13H7V15Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3 19V1H17V5H21V23H7V19H3ZM15 17V3H5V17H15ZM17 7V19H9V21H19V7H17Z"
                                  fill="currentColor"
                                />
                              </svg>
                              Copy CSS
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                      </DropdownMenu.Root>
                    </div>
                  </li>
                );
            }
          )}
        </ul>
      </main>
    );
  }
  return (
    <div className="flex h-full pb-20 flex-col justify-center items-center">
      <InstallGraphic />
      <h1 class="text-center text-slate-600"> Let's get you animated!</h1>
      <p class="text-center text-slate-600 px-[30%]">
        It looks like you've installed the Rombo Animator onto your app. Rombo
        communicates securely with your app to allow you to animate any element
        on your website.
        <br />
        <br />
        Simply click the button below to begin!
        <br />
        <span class="animate-bounce block text-xl pt-4">👇</span>
      </p>
    </div>
  );
}

export default AnimationsList;

import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import "./Installer.css";
import toast from "react-hot-toast";

import { UPDATE_USER } from "../../Mutations";
import { useMutation } from "@apollo/client";

const PlatformSelector = ({
  platform,
  description,
  source,
  image,
  title,
  step,
}) => {
  return (
    <li class="">
      {title}
      {/* .slice(step, step + 1) */}
      {description.map((paragraph, i) => {
        return (
          <div className="flex flex-row justify-start items-baseline py-1.5 rounded-sm my-2">
            <span className="opacity-60 text-sm pr-1">[{i + 1}]</span>
            <p
              className="text-sm font-normal text-white-900"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          </div>
        );
      })}
    </li>
  );
};

function Installer({ checking, platform, refetch, app_url, app_id, auth }) {
  const [updateUser] = useMutation(UPDATE_USER);
  async function optInTailwind() {
    try {
      await updateUser({
        variables: {
          user_id: auth.username,
          full_name: auth.attributes.name,
          app_id: app_id,
          tailwind_opt: true,
        },
      });
      toast.success("We'll keep you posted");
    } catch (e) {
      console.log("Error notifying Tailwind setup", e);
    }
  }

  const platforms = [
    {
      name: "next_tw",
      title: "Install Rombo on NextJS with Tailwind",
      description: [
        'Find your <span class="text-white-900 font-bold">index.html</span> file',
        "Within the <head>section, paste the Rombo script",
        "Deploy your website and refresh the page here",
      ],
      image: "https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
      source: "https://wordpress.org/plugins/insert-headers-and-footers/",
    },
    {
      name: "unknown",
      title: "Install Rombo",
      description: [
        'Find your <span class="text-white-900 font-bold">index.html</span> file',
        "Within the <head>section, paste the Rombo script",
        "Deploy your website and refresh the page here",
      ],
      image: "https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
      source: "https://wordpress.org/plugins/insert-headers-and-footers/",
    },
    {
      name: "gtm",
      title: "Install Rombo with Google Tag Manager",
      description: [
        'Install the <a class="underline font-extrabold decoration-slate-500 underline-offset-2">WPCode plugin</a>',
        'In the plugin, go to <span class="text-white-900 font-bold">Settings > Insert Headers and Footers</span>',
        "Copy and paste the your Rombo code line below into the Scripts in Header section",
      ],
      image: "https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
      source: "https://wordpress.org/plugins/insert-headers-and-footers/",
    },
    {
      name: "WordPress",
      title: "Install Rombo on WordPress",
      description: [
        'Install the <a class="underline font-extrabold decoration-slate-500 underline-offset-2">WPCode plugin</a>',
        `Go to <span onClick="window.open(https://${app_url}/wp-admin/admin.php?page=wpcode-snippet-manager&custom=1)" class="text-white-900 font-bold">add new snippet</span>`,
        "Copy and paste the your Rombo code line below into the Scripts in Header section",
      ],
      image: "https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
      source: "https://wordpress.org/plugins/insert-headers-and-footers/",
    },
    {
      name: "Wix",
      title: "Install Rombo on Wix",
      description: [
        "You can add custom code to your Wix site using the Custom Code feature",
        "Go to <a class='font-extrabold underline-offset-2'>Settings > [Scroll] Advanced > Custom Code</a>",
        "Click <span class='font-extrabold'>Add Code</span> in <span class='font-extrabold'>Head</span>, paste your Rombo code line, and hit <span class='font-extrabold'>Apply</span>!",
      ],
      image:
        "https://en.wikipedia.org/wiki/Wix.com#/media/File:Wix.com_website_logo.svg",
      source:
        "https://support.wix.com/en/article/embedding-custom-code-on-your-site",
    },
    {
      name: "Shopify",
      title: "Install Rombo on Shopify",
      description: [
        "We have a Shopify app that you can install in one click!",
        "Go to <a class='underline font-extrabold decoration-primary-900 underline-offset-2' onClick='window.open(`https://admin.shopify.com/store/rombosed/apps/rombo`)'>the Shopify App listing</a> to get started.",
      ],
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Font_Awesome_5_brands_squarespace.svg/1024px-Font_Awesome_5_brands_squarespace.svg.png?20181017222644",
      source:
        "https://support.squarespace.com/en-us/articles/205815928-Adding-custom-code-to-your-site",
    },
    {
      name: "Squarespace",
      title: "Install Rombo on Squarespace",
      description: [
        "You can add custom code to your Squarespace site using the Code Injection feature.",
        "Go to <a class='underline font-extrabold decoration-primary-900 underline-offset-2' onClick='window.open(`https://whale-iguana-kzad.squarespace.com/config/pages/website-tools/code-injection`)'>Settings > Developer Tools > Code Injection</a> and paste your code in the Header section.",
      ],
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Font_Awesome_5_brands_squarespace.svg/1024px-Font_Awesome_5_brands_squarespace.svg.png?20181017222644",
      source:
        "https://support.squarespace.com/en-us/articles/205815928-Adding-custom-code-to-your-site",
    },
    {
      name: "Webflow",
      title: "Install Rombo on Webflow",
      description: [
        "You can add custom code to your Webflow site using the Custom Code feature.",
        "Go to <a class='underline font-extrabold decoration-primary-900 underline-offset-2' onClick='window.open(`https://webflow.com/dashboard/sites/rombodemo/code`)'>Design > Code > Custom Code</a> and paste your code in the Head section.",
      ],
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Webflow_logo.svg/1200px-Webflow_logo.svg.png",
      source:
        "https://university.webflow.com/lesson/custom-code-in-the-head-and-body-tags",
    },
  ];

  const [copied, setCopied] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(platform);
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(0);
  const [width, setWidth] = useState(0);
  let navigate = useNavigate();

  if (!platform) platform = "unknown";
  if (platform === "NotFound") platform = "unknown";

  let sitePlatform = platforms.filter(
    ({ name }) => name.toLowerCase() === platform
  );

  const containerRef = useRef();

  // If container ref resizes, set width
  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        setWidth(containerRef.current.getBoundingClientRect().width);
      }, 10);
    }
  }, [containerRef && app_url]);

  // let urlWidth = document
  //   .getElementsByClassName("installer-url")[0]
  //   .getBoundingClientRect().width;
  return (
    <div class="modal-transition flex flex-col relative">
      {platform && (
        <aside class="bg-dark-900 relative px-3 flex flex-col justify-between font-semibold text-white py-2 shadow-md outline outline-2 outline-dark-200 shadow-neutral-300 rounded-lg border border-solid border-dark-300">
          <a
            class="px-1 mx-2 rounded-full absolute top-2 bg-neutral-700 w-[25px] h-[25px] flex align-middle justify-center right-0"
            onClick={() => refetch()}
          >
            ↻
          </a>
          <ul>
            {sitePlatform.map((builder) => (
              <li key={builder.platform}>
                <PlatformSelector {...builder} step={2} />
              </li>
            ))}
          </ul>
          <div
            className="installer-snippet"
            onClick={() => {
              window.navigator.clipboard.writeText(
                `<script src="https://rombo.live/${app_id}.js"></script>`
              );
              setCopied(true);
              toast.success("Copied URL to clipboard");
            }}
          >
            {`<script src="`}
            <span className="installer-src">{`https://rombo.live/${app_id}.js`}</span>
            {`"></script>`}
            <a className="installer-copy">
              {!copied ? "Click to copy" : "Copied to clipboard ✓"}
            </a>
          </div>
          {platform.includes("tw") && (
            <div className="bg-white p-2 flex justify-between items-center text-black mt-2 rounded-sm outline outline-gray-200 mb-1">
              <div className="flex">
                <span className="w-6 mr-2 flex">
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#06B6D4"
                      d="M12.001,4.8c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 C13.666,10.618,15.027,12,18.001,12c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C16.337,6.182,14.976,4.8,12.001,4.8z M6.001,12c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624 c1.177,1.194,2.538,2.576,5.512,2.576c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624 C10.337,13.382,8.976,12,6.001,12z"
                    />
                  </svg>
                </span>
                <div className="flex flex-col">
                  Our Tailwind plugin is coming soon! <br />
                  <span className="text-sm opacity-50">
                    You'll soon be able to add beautiful animations with inline
                    CSS
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="rounded-sm flex items-center bg-slate-300 text-black m-0.5 shadow-sm px-2 py-1 text-sm cursor-pointer">
                  <svg
                    class="w-5 mr-1 text-gray-800"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 7a2 2 0 1 1 4 0v4a1 1 0 1 0 2 0V7a4 4 0 0 0-8 0v3H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V7Zm-5 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Get early access
                </span>
                <span
                  onClick={() => optInTailwind()}
                  className="rounded-sm flex items-center bg-black text-white m-0.5 shadow-sm px-2 py-1 text-sm cursor-pointer"
                >
                  <svg
                    class="w-5 mr-1 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17.133 12.632v-1.8a5.406 5.406 0 0 0-4.154-5.262.955.955 0 0 0 .021-.106V3.1a1 1 0 0 0-2 0v2.364a.955.955 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C6.867 15.018 5 15.614 5 16.807 5 17.4 5 18 5.538 18h12.924C19 18 19 17.4 19 16.807c0-1.193-1.867-1.789-1.867-4.175ZM8.823 19a3.453 3.453 0 0 0 6.354 0H8.823Z" />
                  </svg>
                  Notify me
                </span>
              </div>
            </div>
          )}
          {/* <div className="flex align-middle justify-between">
            <a
              className="text-sm opacity-80 hover:opacity-60 cursor-pointer underline underline-offset-2 decoration-slate-400 hover:decoration-primary-500"
              onClick={() => setSelectedPlatform()}
            >
              Not using {platform}?
            </a>
          </div> */}
        </aside>
      )}
    </div>
  );
}

export default Installer;

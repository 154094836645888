import React from "react";
import { ResendVerifcation } from "../../Pages/SignUp";

const VerifyEmail = (emailed) => {
  return (
    <div>
      <h1>Verify Email</h1>
      <svg
        width="120"
        height="120"
        viewBox="0 0 294 289"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M262.333 115.943L137.953 48.5167C137.359 48.1944 136.641 48.1944 136.047 48.5167L11.6795 115.936C10.97 116.32 10.9848 117.344 11.7052 117.707L135.273 180.123C136.987 180.988 139.01 180.982 140.718 180.105L262.313 117.712C263.029 117.344 263.04 116.326 262.333 115.943Z"
          fill="#FAFD70"
        />
        <g filter="url(#filter0_dd_0_1)" className="letter-animate">
          <path
            d="M61.0854 31.8217L59.012 141.369C59.0047 141.754 59.2199 142.11 59.565 142.282L137.647 181.273C137.933 181.415 138.271 181.413 138.554 181.265L213.661 142.28C213.993 142.108 214.201 141.766 214.201 141.392V31.8406C214.201 31.2883 213.753 30.8406 213.201 30.8406H62.0853C61.5404 30.8406 61.0958 31.2769 61.0854 31.8217Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_dd_0_1)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M138.462 181.474C138.173 181.626 137.827 181.627 137.536 181.477L11.4603 116.114C10.7946 115.768 10 116.252 10 117.001V257.217C10 257.77 10.4477 258.217 11 258.217H263C263.552 258.217 264 257.77 264 257.217V117.013C264 116.261 263.2 115.778 262.534 116.129L138.462 181.474Z"
            fill="#FAFD70"
            fill-opacity="0.5"
            shape-rendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_0_1"
            x="44.0117"
            y="0.840618"
            width="200.189"
            height="190.537"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="10" dy="-10" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-5" dy="-5" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_0_1"
              result="effect2_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_0_1"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_dd_0_1"
            x="0"
            y="106"
            width="294"
            height="182.217"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="10" dy="10" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="5" dy="5" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_0_1"
              result="effect2_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <p>
        A verification mail from Rombo is on the way to you! <br />
        <br /> It should arrive in the next few seconds.
        {/* {timeLeft === 0 ? "few" : timeLeft} seconds. <br /> */}
        <br />
        <span className="sub-note">
          If you can't find it, please check your spam folder — and that fails,
          👇
        </span>
      </p>

      <a
        onClick={async () => {
          await ResendVerifcation();
        }}
        className="confirm-link"
      >
        Send link again »
      </a>
    </div>
  );
};

export default VerifyEmail;

import * as Tooltip from "@radix-ui/react-tooltip";

function TooltipWrapper({ children, content }) {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="TooltipContent"
            sideOffset={5}
            class="bg-slate-900 text-xs rounded-sm px-1.5 py-0.5 rounded-sm text-white"
          >
            {content}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export { TooltipWrapper };

import React, { useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import { useParams, useNavigate, Redirect } from "react-router";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { config } from "../aws-exports.js";

export const Verify = ({ auth }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { email, code } = useParams();
  let navigate = useNavigate();

  async function answerCustomChallenge(answer) {
    const user = JSON.parse(localStorage.getItem("cognitoUser"));

    // rehydrate the CognitoUser
    const authenticationData = {
      Username: user.username,
    };
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const poolData = {
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolWebClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
      Username: user.username,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    // After this set the session to the previously stored user session
    cognitoUser.Session = user.session;
    // rehydrating the user and sending the auth challenge answer directly will not
    // trigger a new email
    cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
    cognitoUser.sendCustomChallengeAnswer(answer, {
      async onSuccess(success) {
        // If we get here, the answer was sent successfully,
        // but it might have been wrong (1st or 2nd time)
        // So we should test if the user is authenticated now
        try {
          // This will throw an error if the user is not yet authenticated:
          const user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          window.location.reload();
          console.log(user, "user success");
          console.log(success, "success");
        } catch (e) {
          console.log(e, "User did not enter the right code");
        }
      },
      onFailure(failure) {
        navigate("/signup?link_expired");

        console.log(failure, "Failed because...");
      },
    });
  }

  useEffect(() => {
    answerCustomChallenge(code);
  }, [code && !auth]);

  if (success) {
    window.location.reload();
  }

  return <></>;
};

import "./Button.css";

function Animate({ children }) {
  return <>{children}</>;
}
function renderIcon({ icon, showBackground }) {
  switch (icon) {
    case "google":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="15"
        >
          <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
            <path
              fill="#4285F4"
              d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
            />
            <path
              fill="#34A853"
              d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
            />
            <path
              fill="#FBBC05"
              d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
            />
            <path
              fill="#EA4335"
              d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
            />
          </g>
        </svg>
      );
    case "receiver":
      return (
        <svg
          width="23"
          height="23"
          viewBox="0 -1 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.31396 0.221774C5.31396 0.0992917 5.41326 0 5.53574 0H6.99989V1.40204C6.99989 1.52452 6.9006 1.62381 6.77812 1.62381C6.65564 1.62381 6.55634 1.52452 6.55634 1.40204V0.443549H5.53574C5.41326 0.443549 5.31396 0.344257 5.31396 0.221774Z"
            fill="#050505"
          />
          <path
            opacity="0.3"
            d="M6.77819 0.718754L4.79038 3.14139L4.16919 2.64444L6.157 0.221802H6.77819V0.718754Z"
            fill="url(#paint0_linear_27_59)"
          />
          <path
            d="M1.00374 6.28476C0.449448 5.73047 0.449448 4.83178 1.00374 4.27749C1.55803 3.7232 2.45672 3.7232 3.01101 4.27749C3.56531 4.83178 3.56531 5.73047 3.01101 6.28476C2.45672 6.83906 1.55803 6.83906 1.00374 6.28476Z"
            fill="url(#paint1_linear_27_59)"
            fill-opacity="0.25"
          />
          <path
            d="M2.25814 5.03025C1.70384 4.47595 1.70384 3.57727 2.25814 3.02298C2.81243 2.46868 3.71111 2.46868 4.26541 3.02298C4.8197 3.57727 4.8197 4.47595 4.26541 5.03025C3.71111 5.58454 2.81243 5.58454 2.25814 5.03025Z"
            fill="url(#paint2_linear_27_59)"
            fill-opacity="0.5"
          />
          <path
            d="M3.70076 3.58762C3.14647 3.03333 3.14647 2.13464 3.70076 1.58035C4.25506 1.02606 5.15374 1.02606 5.70803 1.58035C6.26233 2.13464 6.26233 3.03333 5.70803 3.58762C5.15374 4.14191 4.25506 4.14191 3.70076 3.58762Z"
            fill="url(#paint3_linear_27_59)"
          />
          <path
            opacity="0.3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.50056 1.83057L0.019192 5.31194L2.05894 7.35168L5.85614 3.40987C5.59588 3.65777 5.24363 3.80995 4.85583 3.80995C4.05471 3.80995 3.40528 3.16052 3.40528 2.3594C3.40528 2.17356 3.44023 1.99588 3.50391 1.83258L3.50056 1.83057ZM5.9573 3.3033C5.95263 3.30874 5.94792 3.31415 5.94317 3.31953L5.95825 3.30387L5.9573 3.3033Z"
            fill="url(#paint4_linear_27_59)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_27_59"
              x1="6.29842"
              y1="0.41221"
              x2="4.69538"
              y2="1.4211"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1A1A1A" />
              <stop offset="1" stop-color="#1A1A1A" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_27_59"
              x1="3.16308"
              y1="4.42956"
              x2="1.33829"
              y2="6.25435"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#050505" />
              <stop offset="1" stop-color="#050505" stop-opacity="0.69" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_27_59"
              x1="4.41747"
              y1="3.17504"
              x2="2.59268"
              y2="4.99983"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#050505" />
              <stop offset="1" stop-color="#050505" stop-opacity="0.69" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_27_59"
              x1="5.8601"
              y1="1.73241"
              x2="4.03531"
              y2="3.55721"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#050505" />
              <stop offset="1" stop-color="#050505" stop-opacity="0.69" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_27_59"
              x1="4.7578"
              y1="2.53043"
              x2="1.26428"
              y2="6.01401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1A1A1A" />
              <stop offset="1" stop-color="#1A1A1A" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    case "email":
      return (
        <svg
          viewBox="0 0 11 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <path
            d="M8.79221 0.486501C8.93047 0.495383 9.07169 0.525973 9.17446 0.618883C9.53307 0.943077 9.47554 1.51228 9.00186 1.75786L1.21881 5.89902C0.527549 6.25741 -0.262612 5.59532 0.0877544 4.9513L1.00195 3.5C1.00195 3.6091 1.05667 3.39942 1.00195 3.5C14.002 -0.5 1.05667 2.60058 1.00195 2.5L0.0877544 1.04864C-0.262612 0.404619 0.501953 0 1.21881 1.178e-10L8.79221 0.486501Z"
            fill="#050505"
          ></path>
        </svg>
      );
      break;

    case "refresh":
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 150 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_51_2)">
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.149 142.573L144.004 142.573L144.004 60.7623L44.7841 60.7625C54.747 61.4214 64.5229 65.5583 72.1379 73.1733C88.8104 89.8458 88.8104 116.877 72.1379 133.55C68.2702 137.417 63.8451 140.388 59.1211 142.461L59.149 142.573ZM39.1151 60.7625L38.6622 60.7625L38.6702 60.7943C38.8184 60.7829 38.9667 60.7723 39.1151 60.7625Z"
              fill="url(#paint0_linear_51_2)"
            />
            <path
              d="M30.4153 67.8085C29.2498 67.0146 29.2498 65.2964 30.4153 64.5025L47.1126 53.1292C48.4403 52.2248 50.2385 53.1757 50.2385 54.7822L50.2385 77.5287C50.2385 79.1351 48.4403 80.086 47.1126 79.1817L30.4153 67.8085Z"
              fill="#3D3D3D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.0467 104C8.92407 104 8.01658 104.925 8.09585 106.045C9.63726 127.819 27.5804 145 49.4885 145C72.4083 145 90.9885 126.196 90.9885 103C90.9885 86.5317 81.6231 72.2771 67.9885 65.3938C62.4185 62.5818 56.136 61 49.4885 61C49.3216 61 49.1549 61.001 48.9885 61.003V71.004C49.1548 71.0013 49.3215 71 49.4885 71C54.5317 71 59.2743 72.1965 63.4818 74.3207C73.836 79.5479 80.9885 90.4035 80.9885 103C80.9885 120.786 66.7729 135 49.4885 135C33.1673 135 19.5824 122.326 18.119 105.939C18.0228 104.862 17.1439 104 16.0628 104H10.0467Z"
              fill="#3D3D3D"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_51_2"
              x1="37.4781"
              y1="99.7752"
              x2="145.008"
              y2="97.6188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1A1A1A" />
              <stop offset="1" stop-color="#1A1A1A" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_51_2">
              <rect width="179" height="206" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "animations":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_13_10)">
            <circle
              cx="63"
              cy="44"
              r="16"
              fill="url(#paint0_linear_13_10)"
              fill-opacity="0.25"
            />
            <circle
              cx="43"
              cy="44"
              r="16"
              fill="url(#paint1_linear_13_10)"
              fill-opacity="0.5"
            />
            <circle cx="21" cy="44" r="16" fill="url(#paint2_linear_13_10)" />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.9999 60.0177L76.5001 60.0177L76.5 27.5L14.8122 28.6527C18.8627 28.7512 22.8836 30.346 25.9748 33.4372C32.3605 39.8229 32.3605 50.1762 25.9748 56.5619C24.4934 58.0433 22.7985 59.181 20.9892 59.975L20.9999 60.0177ZM13.1563 28.6959C13.237 28.6897 13.3177 28.6841 13.3984 28.6792L13.1533 28.6837L13.1563 28.6959Z"
              fill="url(#paint3_linear_13_10)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_13_10"
              x1="47"
              y1="41.5758"
              x2="76.0909"
              y2="41.5758"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#050505" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#050505" : "#FFFFF8"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear_13_10"
              x1="27"
              y1="41.5758"
              x2="56.0909"
              y2="41.5758"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#050505" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#050505" : "#FFFFF8"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint2_linear_13_10"
              x1="5"
              y1="41.5758"
              x2="34.0909"
              y2="41.5758"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#050505" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#050505" : "#FFFFF8"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint3_linear_13_10"
              x1="16.5569"
              y1="44.4177"
              x2="72.1716"
              y2="44.4969"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"}
                stop-opacity="0"
              />
            </linearGradient>
            <clipPath id="clip0_13_10">
              <rect width="88" height="88" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "library":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_13_11)">
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39 35.5L65 9.23611L41.7474 0L19.8829 20.5881C21.9918 18.9651 24.6332 18 27.5 18C34.4036 18 40 23.5964 40 30.5C40 32.2701 39.6321 33.9543 38.9685 35.4803L39 35.5ZM18.0668 22.2982L18 22.3611L18.0081 22.3662C18.0276 22.3434 18.0471 22.3208 18.0668 22.2982Z"
              fill="url(#paint0_linear_13_11)"
            />
            <circle
              cx="27.5"
              cy="30.5"
              r="12.5"
              fill="url(#paint1_linear_13_11)"
            />
            <circle
              cx="27.5"
              cy="61.5"
              r="12.5"
              fill="url(#paint2_linear_13_11)"
            />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39 66.5L65 40.2361L41.7474 31L19.8829 51.5881C21.9918 49.9651 24.6332 49 27.5 49C34.4036 49 40 54.5964 40 61.5C40 63.2701 39.6321 64.9543 38.9685 66.4803L39 66.5ZM18.0668 53.2982L18 53.3611L18.0081 53.3662C18.0276 53.3434 18.0471 53.3208 18.0668 53.2982Z"
              fill="url(#paint3_linear_13_11)"
            />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67 36.5L93 10.2361L69.7474 1L47.8829 21.5881C49.9918 19.9651 52.6332 19 55.5 19C62.4036 19 68 24.5964 68 31.5C68 33.2701 67.6321 34.9543 66.9685 36.4803L67 36.5ZM46.0668 23.2982L46 23.3611L46.0081 23.3662C46.0276 23.3434 46.0471 23.3208 46.0668 23.2982Z"
              fill="url(#paint4_linear_13_11)"
            />
            <circle
              cx="55.5"
              cy="31.5"
              r="12.5"
              fill="url(#paint5_linear_13_11)"
            />
            <circle
              cx="55.5"
              cy="62.5"
              r="12.5"
              fill="url(#paint6_linear_13_11)"
            />
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67 67.5L93 41.2361L69.7474 32L47.8829 52.5881C49.9918 50.9651 52.6332 50 55.5 50C62.4036 50 68 55.5964 68 62.5C68 64.2701 67.6321 65.9543 66.9685 67.4803L67 67.5ZM46.0668 54.2982L46 54.3611L46.0081 54.3662C46.0276 54.3434 46.0471 54.3208 46.0668 54.2982Z"
              fill="url(#paint7_linear_13_11)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_13_11"
              x1="27.5"
              y1="31"
              x2="49.5"
              y2="4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear_13_11"
              x1="15"
              y1="28.6061"
              x2="37.7273"
              y2="28.6061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint2_linear_13_11"
              x1="15"
              y1="59.6061"
              x2="37.7273"
              y2="59.6061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint3_linear_13_11"
              x1="27.5"
              y1="62"
              x2="49.5"
              y2="35"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0"
              />
            </linearGradient>
            <linearGradient
              id="paint4_linear_13_11"
              x1="55.5"
              y1="32"
              x2="77.5"
              y2="5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0"
              />
            </linearGradient>
            <linearGradient
              id="paint5_linear_13_11"
              x1="43"
              y1="29.6061"
              x2="65.7273"
              y2="29.6061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint6_linear_13_11"
              x1="43"
              y1="60.6061"
              x2="65.7273"
              y2="60.6061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint7_linear_13_11"
              x1="55.5"
              y1="63"
              x2="77.5"
              y2="36"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FBFBF5"}
                stop-opacity="0"
              />
            </linearGradient>
            <clipPath id="clip0_13_11">
              <rect width="88" height="88" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "settings":
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 -5 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M74 44.5C74 52.5081 67.5081 59 59.5 59C51.4919 59 45 52.5081 45 44.5C45 36.4919 51.4919 30 59.5 30C67.5081 30 74 36.4919 74 44.5Z"
            stroke="#3D3D3D"
            stroke-width="4"
          />
          <circle cx="24" cy="44" r="16" fill="url(#paint0_linear_13_75)" />
          <path
            opacity="0.3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.5905 59.5992L60.0906 59.5991L60.0906 28.2651L22.0886 28.2652C25.9045 28.5175 29.6487 30.102 32.5653 33.0186C38.9511 39.4043 38.9511 49.7576 32.5653 56.1433C31.084 57.6247 29.3891 58.7624 27.5798 59.5565L27.5905 59.5992ZM19.9173 28.2652L19.7439 28.2652L19.7469 28.2773C19.8037 28.273 19.8605 28.2689 19.9173 28.2652Z"
            fill="url(#paint1_linear_13_75)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_13_75"
              x1="8"
              y1="41.5758"
              x2="37.0909"
              y2="41.5758"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#050505" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#050505" : "#FFFFF8"}
                stop-opacity="0.69"
              />
            </linearGradient>
            <linearGradient
              id="paint1_linear_13_75"
              x1="19.2903"
              y1="43.2074"
              x2="60.4754"
              y2="42.3814"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"} />
              <stop
                offset="1"
                stop-color={!showBackground ? "#1A1A1A" : "#FFFFF8"}
                stop-opacity="0"
              />
            </linearGradient>
          </defs>
        </svg>
      );
    default:
      break;
  }
}

function Button({
  signup,
  iconEnd,
  iconStart,
  shadow,
  disabled,
  children,
  icon,
  showBackground,
  showBorder,
  onClick,
  login,
  bright,
  danger,
  normal,
  type,
}) {
  const baseClasses = `flex rounded-md px-2.5 py-1.5 w-full items-center justify-end`;
  const dynamicClasses = `${shadow ? "shadow-md" : ""} ${
    showBackground ? "button-bg text-white" : "text-dark"
  } ${
    showBorder
      ? "border border-solid border-primary-700 shadow-md shadow-primary-700 "
      : ""
  } ${login ? "bg-primary-gradient" : ""} ${
    normal
      ? "text-dark-900 tracking-[-0.15px] text-md bg-primary-500 hover:bg-primary-400"
      : ""
  } ${
    bright
      ? "text-dark-900 bg-primary-500 hover:bg-primary-300 w-auto justify-center py-1 px-1 rounded-sm border border-primary-700 send-email"
      : ""
  }
  ${
    danger
      ? "text-red-900 border !text-md !px-1 !py-0.5 border-red-500 bg-red-400 rounded-sm hover:bg-red-300"
      : ""
  }
  ${iconEnd ? "flex-row" : "flex-row-reverse"}`;

  return (
    <Animate token="yc-slide-up" delay="200ms" speed="300ms">
      <button
        disabled={disabled || false}
        className={`${baseClasses} ${dynamicClasses} z-10`}
        {...{ onClick, type }}
      >
        <span className="px-1 ">{children}</span>
        {icon && (
          <span className="pl-1 opacity-80">
            {renderIcon({ icon, showBackground })}
          </span>
        )}
      </button>
    </Animate>
  );
}

export { Button };

import React, { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { Formik, Field, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

async function signUpPasswordless(email, fullName) {
  // console.log(email, fullName);
  try {
    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        name: fullName,
      },
    };
    await Auth.signUp(params);
  } catch (error) {
    console.log(error.code);
    if (error.code === "UsernameExistsException") return;
  }
}

async function signInPasswordless(email) {
  console.log("Signing in user", email);
  try {
    let user = await Auth.signIn(email);

    localStorage.setItem(
      "cognitoUser",
      JSON.stringify({
        username: email,
        session: user.Session,
      })
    );
  } catch (error) {
    console.log(error);
    return error;
  }
}

function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(nr) {
  return nr.toString(16).padStart(2, "0");
}

async function SignUpUser(username, password) {
  return await Auth.signUp(username, password).catch((e) => e);
}

async function VerifyCode(username, code) {
  return await Auth.confirmSignUp(username, code).catch((e) => console.log(e));
}

async function ResendVerifcation(username) {
  return await Auth.resendSignUp(username).catch((e) => console.log(e));
}

async function LoginUser(username, password) {
  return await Auth.signIn(username, password).catch((e) => console.log(e));
}

async function retrieveLoginToken() {
  try {
    const session = await Auth.currentSession();
    console.log("jwt", session.getAccessToken().getJwtToken());
    return session;
  } catch (e) {
    console.log(e);
    return e;
  }
}
async function signInGoogle(res) {
  console.log("Signing in with Google", res);
  let { email, name, sub } = jwt_decode(res.credential);
  console.log(email, name, sub);
  const credentials = await Auth.federatedSignIn({
    provider: "Google",
    user: {
      email,
      name,
    },
    id_token: sub,
  });
}

const getAWSCredentials = async (googleUser) => {
  const { id_token, expires_at } = googleUser.getAuthResponse();
  const profile = googleUser.getBasicProfile();
  let user = {
    email: profile.getEmail(),
    name: profile.getName(),
    sub: id_token,
  };
  try {
    const credentials = await Auth.federatedSignIn({
      provider: "Google",
    });
    console.log(credentials, googleUser.getAuthResponse);
  } catch (e) {
    console.log(e);
  }
};

const createGoogleScript = () => {
  // load the Google SDK
  const script = document.createElement("script");

  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  // script.onload = initGapi;
  document.body.appendChild(script);
};

export {
  createGoogleScript,
  signUpPasswordless,
  signInPasswordless,
  VerifyCode,
  ResendVerifcation,
};
